/* General App Styling */
.App {
  text-align: center;
  font-family: "Calibri", sans-serif !important; /* Apply Calibri font */
  font-size: 106px !important; /* Set text size to 106px */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Header Styling */
.App-header {
  background-color: #333 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Logo Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Video Styling */
video {
  height: auto !important; /* Maintain aspect ratio */
  width: 40% !important; /* Ensure it doesn't exceed chat width */
  border-radius: 10px !important; /* Optional: Apply rounded corners */
}

/* Image Styling in Message Blocks */
.bpMessageBlocksBubble img {
  height: 220px !important; /* Maintain aspect ratio */
  width: 520px !important;
  border-radius: 10px !important; /* Optional: Apply rounded corners */
  object-fit: cover; /* Ensure the image covers the entire area */
  object-position: 50% 15%; /* Horizontal 50% (center), Vertical 19% */
}

/* Header Container Styling */
.bpHeaderContainer {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background-color: #333 !important;
  border-top: black;
  color: white !important;
  font-weight: bold !important;
  pointer-events: none; 
}

.bpHeaderContainer p {
  background-color: #333 !important;
  color: white !important; /* Use 'color' instead of 'font-color' */
}

.bpHeaderContainer span img {
  width: 50px;
  height: 50px;
}

.bpHeaderContainer h2 {
  background-color: #333 !important;
  font-size: 22px !important;
  color: white !important; /* Use 'color' instead of 'font-color' */
}

/* Hover Effect for Header Container */
.bpHeaderContainer :hover {
  background-color: #333 !important;
  font-family: Helvetica Neue, Helvetica, Arial !important;
}

/* Header Actions Styling */
.bpHeaderContentActionsContainer {
  pointer-events: all !important;
}



.bpHeaderContentActionsContainer :nth-child(1) {
  color: #fff;
  width: 25px !important;
  padding: 0 !important;
  height: 25px !important;
  margin-right: 10px !important;
}


.bpHeaderContentActionsContainer :nth-child(2) {
  background-image: url('../public/info\ \(2\).png') !important;
  border-radius: 50%;
  background-size:cover;
  width: 25px !important;
  height: 25px !important;
  background-color: white !important;
  font-family: inherit !important;
}



.bpHeaderContentActionsContainer :nth-child(2) path {
  display: none !important;

}

/* Message List Marquee Container */
.bpMessageListMarqueeContainer {
  display: none !important;
}

/* Container Styling */
.bpContainer {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-radius: none;
  border-top: black;
  font-size: 14px !important;
  font-family: Helvetica Neue, Helvetica, Arial !important;
}







